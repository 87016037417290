section .nfts_container{
  margin-top: 30px;
  color: #FFFFFF;
  height: 100%;


  .nfts_list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 80px;
    //margin-top: 45px;
    width: 93%;

    &-item{
      width: 12.1%;
      margin: 0 4px;

      &:hover{
        transform: scale(1.2);
        transition: transform 0.2s ease-in-out;
      }


      img{
        width: 100%;
        height: 100%;
      }
    }
  }


  @media screen and (max-width: 1699px){
    height: 108vh;
  }

}