section .tokenomics_container{
  color: #FFFFFF;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;



  .tokenomics_content{
  position: relative;

    .tokenomic_content-diagram{
      width: 448px;
      height: 448px;

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      position: relative;

      img{
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
      }


      span{
        position: absolute;
        top: 42%;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 700;
        font-size: 45px;
        color: #FFFFFF;
      }
    }

    .green-line_blck{
      position: absolute;
      top: 20%;
      left: -65%;
      width: 395px;
      border-bottom: 6px solid #B6CF41;
      font-size: 1.125rem;
      padding-bottom: 10px;

      p{
        margin: 0;
      }
    }

    .pink-line_blck{
      position: absolute;
      top: 55%;
      left: -66%;
      width: 395px;
      border-bottom: 6px solid #CF1A68;
      font-size: 1.125rem;
      padding-bottom: 10px;
      padding-right: 20px;

      p{
        margin: 0;
      }
    }

    .purple-line_blck{
      position: absolute;
      top: 25%;
      right: -60%;
      width: 395px;
      border-bottom: 6px solid #854796;
      font-size: 1.125rem;
      padding-bottom: 10px;
      text-align: end;

      p{
        margin: 0;
      }
    }

    .orange-line_blck{
      position: absolute;
      top: 50%;
      right: -65%;
      width: 395px;
      border-bottom: 6px solid #FF8E16;
      font-size: 1.125rem;
      padding-bottom: 10px;
      text-align: end;

      p{
        margin: 0;
      }
    }
  }
}