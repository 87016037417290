section .main-container{
  height: 100%;
  position: relative;
  background-image: url("../../images/vectors/main-bottom-vector2.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;

  .vector-1 img{
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translateX(-50%);

    &:first-child{
      bottom: 67px;
      width: 47%;
      height: 47.4%;
    }

    &:last-child{
      width: 100%;
    }

  }

  .main-logo{
    width: 506px;
    height: 207px;
    margin: 93px auto 0 auto;

    img{
      width: 100%;
      height: 100%;
    }
    
    @media screen and (max-width: 1699px) {
      width: 450px;
      height: 175px;
      margin: 75px auto 0 auto;
    }
  }

  .main-start-journey{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45%;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);


    img{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      width: 152.6%;
      height: 105%;
    }
    .main-content{
      z-index: 2;
      text-align: center;

      h1{
        font-weight: 700;
        font-size: 3.125rem;
        position: relative;
        color: #FFFFFF;
        margin-top: 45px;
        margin-bottom: 22px;
        text-shadow: 0 0 2px #000, 0 0 2px #000, 0 0 2px #000, 0 0 2px #000;


        @media screen and (max-width: 1699px) {
          font-size: 2.125rem;
        }
      }

      .start-btn{
        background-image: url("../../images/start_btn 1.png");
        background-repeat: no-repeat;
        background-size: contain;
        font-weight: 600;
        font-size: 2rem;
        line-height: 43px;
        text-align: center;
        color: #FFFFFF;
        width: 285.85px;
        height: 87px;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        @media screen and (max-width: 1699px) {
          background-repeat: no-repeat;
          background-size: contain;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 43px;
          text-align: center;
          color: #FFFFFF;
          width: 240.85px;
          height: 72px;
          background-color: transparent;
          border: 0;
          cursor: pointer;
        }

      }

      .start-btn-hover{
        background-image: url("../../images/start_btn 2.png");
        background-repeat: no-repeat;
        background-size: contain;
        font-weight: 600;
        font-size: 2rem;
        line-height: 43px;
        text-align: center;
        color: #FFFFFF;
        width: 285.85px;
        height: 87px;
        background-color: transparent;
        border: 0;
        cursor: pointer;

        @media screen and (max-width: 1699px) {
          background-repeat: no-repeat;
          background-size: contain;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 43px;
          text-align: center;
          color: #FFFFFF;
          width: 240.85px;
          height: 72px;
          background-color: transparent;
          border: 0;
          cursor: pointer;
        }

      }
    }

  }

}