section .roadmap_container{
  color: #FFFFFF;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;


  .roadmap_content{
    width: 1422px;

    &_line{
      text-align: center;

      height: 0;

      border: 4px solid #FFFFFF;
      transform: rotate(0.13deg);
      margin: 0;
      display: flex;


      &-item{

        @media screen and (max-width: 1699px){
          width: 165px;
        }
      }

      li:nth-child(even) {
        width: 19.2%;
        height: 440px;

        background: linear-gradient(180deg, rgba(115, 150, 71, 0.35) 0%, rgba(194, 210, 167, 0.245) 100%);

        .number{
          font-weight: 600;
          font-size: 11.25rem;
          color: rgba(255, 255, 255, 0.1);


          @media screen and (max-width: 1699px){
            font-size: 7rem;
          }
        }

        .name{
          color: #D8C72C;
          margin-top: 40px;
          margin-bottom: 18px;
          font-weight: 600;
          font-size: 1.375rem;
          padding: 0 20px;


          @media screen and (max-width: 1699px){
            font-size: 0.9rem;
          }
        }

        .ellipse{
          width: 62px;
          height: 62px;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          margin: -33px auto;

          @media screen and (max-width: 1699px){
            width: 54px;
            height: 54px;
            margin: -30px auto;
          }

          &_2{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            margin: 13px auto;
            background: #D8C72C;

            @media screen and (max-width: 1699px){
              width: 1.5rem;
              height: 1.5rem;
              margin: 15px auto;
            }
          }
        }


        @media screen and (max-width: 1699px){
          width: 100%;
          height: 340px;
        }
      }

      li:nth-child(odd) {
        width: 19.2%;
        height: 440px;
        transform: translateY(-100%);

        background: linear-gradient(180deg, rgba(115, 150, 71, 0) 0%, rgba(115, 150, 71, 0.35) 100%);

        .number{
          font-weight: 600;
          font-size: 11.25rem;
          color: rgba(255, 255, 255, 0.1);


          @media screen and (max-width: 1699px){
            font-size: 7rem;
          }
        }

        .name{
          color: #D8C72C;
          margin-top: 2rem;
          font-size: 1.375rem;
          font-weight: 600;
          padding: 0 20px;

          @media screen and (max-width: 1699px){
            font-size: 0.9rem;
          }
        }

        .ellipse{
          width: 62px;
          height: 62px;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          margin: 20px auto;

          @media screen and (max-width: 1699px){
            width: 54px;
            height: 54px;
            margin: 24px auto;
          }

          &_2{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            margin: 13px auto;
            background: #D8C72C;

            @media screen and (max-width: 1699px){
              width: 1.5rem;
              height: 1.5rem;
              margin: 15px auto;
            }
          }
        }

        @media screen and (max-width: 1699px){
          width: 100%;
          height: 340px;
        }
      }

      li:last-child {
        width: 19.2%;
        height: 440px;

        background: linear-gradient(180deg, rgba(150, 71, 114, 0.35) 0%, rgba(150, 71, 114, 0) 100%);

        .number{
          font-weight: 600;
          font-size: 11.25rem;
          color: rgba(255, 255, 255, 0.1);

          @media screen and (max-width: 1699px){
            font-size: 7rem;
          }
        }

        .name{
          color: #D8C72C;
          margin-top: 40px;
          margin-bottom: 79px;
          font-size: 1.375rem;
          font-weight: 600;
          padding: 0 20px;

          @media screen and (max-width: 1699px){
            font-size: 0.9rem;
            margin-bottom: 105px;
          }
        }

        .ellipse{
          width: 62px;
          height: 62px;
          border: 3px solid #FFFFFF;
          border-radius: 50%;
          margin: -33px auto;

          @media screen and (max-width: 1699px){
            width: 54px;
            height: 54px;
            margin: -30px auto;
          }

          &_2{
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            margin: 13px auto;
            background: transparent;
            border: 3px solid #FFFFFF;


            @media screen and (max-width: 1699px){
              width: 1.5rem;
              height: 1.5rem;
              margin: 12px auto;
            }
          }
        }

        @media screen and (max-width: 1699px){
          width: 100%;
          height: 340px;
        }
      }
      }


    @media screen and (max-width: 1699px){
      width: 1000px;
    }

    }

}