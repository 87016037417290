footer{
  width: 100%;
  height: 240px;
  background: #171020;


  .footer-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;


    .footer-container_logo{
      width: 222px;
      height: 87px;
      filter: drop-shadow(0px 8px 15px #000000);

      img{
        width: 100%;
        height: 100%;
      }
    }

    .footer-container_center{
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
      color: #FFFFFF;
      margin-top: 55px;

      .footer-container_start-btn{
        background-image: url("../../images/start_btn 1.png");
        background-repeat: no-repeat;
        background-size: contain;
        font-weight: 600;
        font-size: 2rem;
        line-height: 43px;
        text-align: center;
        color: #FFFFFF;
        width: 285.85px;
        height: 87px;
        background-color: transparent;
        border: 0;
        cursor: pointer;
        margin-bottom: 25px;


        @media screen and (max-width: 1699px){
          margin-bottom: 10px;
          font-size: 1.75rem;
          width: 232px;
          height: 69px;
        }
      }
      .copy-r{
        display: flex;
        align-items: center;

          p{
            font-weight: 400;
            font-size: 1.25rem;
            margin-left: 8px;
            color: #AFAFAF;

            @media screen and (max-width: 1699px){
              font-size: 1rem;
            }

          }
      }



      @media screen and (max-width: 1699px){
        margin: 0;
      }
    }



    .footer-container_social-media{
      display: flex;
      align-items: center;

      img{
        margin: 4px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1699px){
    height: 225px;
  }

  @media screen and (max-width: 1599px){
    height: 195px;
  }
}